import * as React from 'react';

import { GeneralHead } from '@src/components/GeneralHead';
import { NavigationBar } from '@src/components/NavigationBar';
import { Footer } from '@src/components/Footer';
import { initLanguage } from '@src/utils/translation';
import { Layout } from '@src/components/Layout';
import { useQueryString } from '@src/hooks/useQueryString';
import ProjectDetailPage from '@src/components/pages/ProjectDetail';
import { PageProps } from 'gatsby';

interface IndexProps {
  pageContext: PageProps<unknown, { projectId: string }>['pageContext'];
}

export default function Index({ pageContext: { projectId } }: IndexProps) {
  const { isPreparing } = useQueryString();

  React.useEffect(() => {
    initLanguage();
  }, []);

  return (
    !isPreparing && (
      <Layout
        header={({ scrollElement }) => (
          <NavigationBar
            type="GAME_DETAIL"
            scrollRef={scrollElement}
            hideLink
          />
        )}
        footer={undefined}
      >
        {({ scrollElement }) => (
          <ProjectDetailPage
            projectId={projectId}
            scrollElement={scrollElement}
          />
        )}
      </Layout>
    )
  );
}

export const Head = () => {
  return (
    <>
      <GeneralHead />
      <title>모두의 놀이터, 리얼월드 - 리얼월드</title>
    </>
  );
};
